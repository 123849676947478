import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";

import { createUseStyles } from "react-jss";
import { TextField } from "@mui/material";
import { AddAlert, Assignment, Search } from "@mui/icons-material";
import { Permissions } from "../../config";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import {
    createSpecialUser,
    deleteSpecialUser,
    searchSpecialUsers,
    updateSpecialUser,
    getSpecialUsers,
} from "../../store/actions/specialUser";
import AddSpecialUser from "./AddSpecialUser";
import { getSpecialCompany } from "../../store/actions/specialCompany";

import Autocomplete from "@mui/material/Autocomplete";
import { LoaderSpinner } from "../../components/Loader";

const stylesAdd = {
    txtArea: {
        height: "84px",
        width: "100%",
        borderColor: "rgb(210, 210, 210)",
        fontSize: "14px",
        fontFamily: "Roboto, Helvetica, Arial",
        fontWeight: 400,
        lineHeight: 1.42857,
        resize: "none",
    },
    txtAreaError: {
        borderColor: "#DE2C48",
    },
    txtAreaSuccess: {
        borderColor: "#6bbd6e",
    },
    txtSuccess: {
        color: "#6bbd6e",
        fontWeight: "bold",
    },
    choiche: {
        position: "relative",
        float: "left",
        textAlign: "center",
        cursor: "pointer",
        // marginTop: "20px"
        width: "60px",
        margin: "5px 0",
    },
    iconCheckbox: {
        width: "50px",
        height: "50px",
    },
    logoImg: {
        width: "90%",
        height: "90%",
        opacity: "0.5",
        borderRadius: "50%",
    },
    companies: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    error: {
        textAlign: "center",
        color: "#C42431",
        fontWeight: "bold",
    },
    iconSelected: {
        color: "#C42431",
        opacity: 1,
        border: "2px solid #C42431",
        "& > span:first-child": {
            borderColor: "#C42431",
        },
        "& img": {
            opacity: 1,
        },
    },
    ...stylesAlert,
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
};

const style = {
    choiche: {
        position: "relative",
        float: "left",
        textAlign: "center",
        cursor: "pointer",
        width: "45px",
    },
    iconCheckbox: {
        width: "40px",
        height: "40px",
    },
    logoImg: {
        width: "90%",
        height: "90%",
        opacity: "0.5",
        borderRadius: "50%",
    },
    ...styles,
    ...stylesAlert,
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
};

const useStyles = createUseStyles(style);
const useStylesAdd = createUseStyles(stylesAdd);

const SpecialUsers = ({ t }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const classes2 = useStylesAdd();

    const { specialUsers, loadMore, loading } = useSelector((state) => state.specialUser);
    const { status, message, type } = useSelector((state) => state.specialUser.notification);
    const { specialCompanies } = useSelector((state) => state.specialCompanies);
    const [alert, setAlert] = useState(null);

    const [specialUsersState, setSpecialUsersState] = useState([]);
    const userStorage = JSON.parse(localStorage.getItem("user"));
    const userPermission = userStorage.permission;
    const canUpdate = userPermission.includes(Permissions.SPECIAL_USER_UPDATE);
    const canDelete = userPermission.includes(Permissions.SPECIAL_USER_DELETE);
    let companyIdStorage = userStorage.user.torgid_company_id;

    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(100);
    const [isActiveLoadMore, setIsActiveLoadMore] = useState(true);
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        dispatch(getSpecialUsers(page, false));
        dispatch(getSpecialCompany("byGroup"));
    }, [dispatch]);

    useEffect(() => {
        setSpecialUsersState(specialUsers.results);
    }, [specialUsers.results]);

    if (loading) return <LoaderSpinner />;

    const loadMoreUsers = () => {
        dispatch(getSpecialUsers(page + 1, true));
        setPage(page + 1);
    };

    const onSubmit = (data) => {
        let temp = data.users.replace(/^\s+|\s+$/g, "").split(/\s+/);
        data.users = temp.join(" ");
        let companies = data.companies;
        companies.push("1");
        data.groups = companies.join(",");
        dispatch(createSpecialUser({ ...data }));
    };

    const hideAlert = () => {
        setAlert(null);
    };

    const searchHandler = () => {
        if (search.length > 1) {
            setIsActiveLoadMore(false);
            let newSearch = search.replace(/%/g, "");
            dispatch(searchSpecialUsers(newSearch));
            return;
        }
        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="The search text must contain at least 2 characters"
                onConfirm={() => hideAlert()}
                showConfirm={false}
            />
        );
        setTimeout(hideAlert, 2000);
    };

    const htmlAlert = () => {
        setAlert(
            <AddSpecialUser
                classesAdd={classes2}
                companies={specialCompanies.results}
                onCancel={() => hideAlert()}
                onSubmit={(data, update) => onSubmit(data, update)}
            />
        );
    };

    const handleChange = (index, newValue) => {
        let filterNewValue = newValue.filter((item) => item !== undefined);
        let newState = Object.assign([], specialUsersState);
        newState[index].torgid_special_companies = filterNewValue.map((item) => item.id);
        setSpecialUsersState(newState);
    };

    const updateBtn = (index, id, companies) => {
        console.log("updateBtn", companies);

        return (
            <Button
                key={"save" + index}
                color="success"
                className={classes.actionButton}
                onClick={() => dispatch(updateSpecialUser(id, companies))}
            >
                {" "}
                {t("save")}{" "}
            </Button>
        );
    };

    const changeFilter = (item) => {
        let ids = item.map((data) => data.id);
        setFilter(ids);
        dispatch(getSpecialUsers(page, false, ids));
    };

    const connectedUsersData = specialUsersState
        ? specialUsersState.map((value, index) => {
              let company_id = value.torgid_company_id || companyIdStorage;
              let user_id = value.torgid_user_id;
              let name = value.torgid_user ? value.torgid_user.name : "";
              let email = value.torgid_user ? value.torgid_user.email : value.email;
              let phone = value.torgid_user ? value.torgid_user.phone : value.phone;
              let kennitala = value.torgid_user ? value.torgid_user.kennitala : value.kennitala;
              let spec_companies = value.torgid_special_companies
                  ? Array.isArray(value.torgid_special_companies)
                      ? value.torgid_special_companies
                      : value.torgid_special_companies.split(",").map(Number)
                  : [];

              return [
                  index + 1,
                  user_id,
                  name,
                  email,
                  phone,
                  kennitala,
                  <Autocomplete
                      multiple
                      id={"companies"}
                      onChange={(event, newValue) => {
                          handleChange(index, newValue);
                      }}
                      value={
                          specialCompanies && spec_companies
                              ? spec_companies.map((item) =>
                                    specialCompanies.results?.find((spec) => spec.id === item)
                                )
                              : []
                      }
                      options={
                          specialCompanies && specialCompanies.results
                              ? specialCompanies.results
                              : []
                      }
                      getOptionLabel={(option) => option?.name}
                      getOptionSelected={(option, value) => value && option.id === value.id}
                      // renderOption={(props, option) => (
                      //   <div key={option.id+'opt_row'}><img key={option.id+'imgId'} loading="lazy" width="20" src={option.logo} style={{marginRight: '10px'}}/>{option.name}</div>
                      // )}
                      style={{ marginTop: 10 }}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="standard"
                              label={`${t("choose_category")}`}
                          />
                      )}
                  />,
                  [
                      canUpdate && updateBtn(index, value.id, value.torgid_special_companies),
                      canDelete && (
                          <DeleteButton
                              key={value.id + "_delete"}
                              action={deleteSpecialUser(value.id)}
                              setLimit={() => setPageLimit(pageLimit - 1)}
                          />
                      ),
                  ],
              ];
          })
        : [[]];

    let filterDropdown = (
        <Autocomplete
            multiple
            id={"companies"}
            onChange={(event, newValue) => {
                changeFilter(newValue);
            }}
            value={
                specialCompanies && filter
                    ? filter.map((item) =>
                          specialCompanies.results?.find((spec) => spec.id === item)
                      )
                    : []
            }
            options={specialCompanies && specialCompanies.results ? specialCompanies.results : []}
            getOptionLabel={(option) => option?.name}
            getOptionSelected={(option, value) => value && option.id === value.id}
            // renderOption={(props, option) => (
            //   <div><img key={option.id+'imgId'} loading="lazy" width="20" src={option.logo} style={{marginRight: '10px'}}/>{option.name}</div>
            // )}
            style={{ marginTop: 10 }}
            renderInput={(params) => (
                <TextField {...params} variant="standard" label={`${t("special_companies")}`} />
            )}
        />
    );

    return (
        <GridContainer>
            {alert}
            <SnackbarCustom
                place="br"
                icon={AddAlert}
                color={type === "success" ? "success" : "warning"}
                message={message}
                open={status}
            />
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="info" icon>
                        <GridContainer justifyContent="space-between" container>
                            <GridItem md={4}>
                                <CardHeader>
                                    <CardIcon color="rose">
                                        <Assignment />
                                    </CardIcon>
                                    <span className={classes.tableTitle}>{t("menu.members")} </span>
                                    {isActiveLoadMore && (
                                        <span className={classes.tableCount}>
                                            {" "}
                                            {`Sýni ${
                                                page * pageLimit < specialUsers.count
                                                    ? page * pageLimit
                                                    : specialUsers.count
                                            } af ${specialUsers.count} félögum`}{" "}
                                        </span>
                                    )}
                                </CardHeader>
                            </GridItem>
                            <GridItem>
                                <CustomInput
                                    inputProps={{
                                        value: search,
                                        onChange: (event) => setSearch(event.target.value),
                                        onKeyPress: (e) => e.key === "Enter" && searchHandler(),
                                        placeholder: t("search"),
                                        inputProps: {
                                            "aria-label": "Search",
                                            className: classes.searchInput,
                                        },
                                    }}
                                />
                                <Button
                                    color="rose"
                                    aria-label="edit"
                                    justIcon
                                    size="sm"
                                    onClick={() => searchHandler()}
                                >
                                    <Search
                                        className={
                                            classes.headerLinksSvg + " " + classes.searchIcon
                                        }
                                    />
                                </Button>
                            </GridItem>
                            <GridItem>
                                {userPermission.includes(Permissions.SPECIAL_USER_CREATE) && (
                                    <Button
                                        color="rose"
                                        className={classes.addButton}
                                        onClick={htmlAlert}
                                    >
                                        {" "}
                                        {t("add_new_special_user")}{" "}
                                    </Button>
                                )}
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <Table
                            striped
                            tableHead={[
                                "#",
                                t("user_id"),
                                t("name"),
                                t("email"),
                                t("phone"),
                                t("kennitala"),
                                filterDropdown,
                                t("actions"),
                            ]}
                            tableData={connectedUsersData}
                            customCellClasses={[classes.center, classes.right, classes.right]}
                            customClassesForCells={[0, 4, 5, 6]}
                            customHeadCellClasses={[classes.center, classes.right, classes.right]}
                            customHeadClassesForCells={[0, 4, 5, 6]}
                        />
                    </CardBody>
                    <GridItem xs={12}>
                        {isActiveLoadMore && (
                            <Button
                                color="rose"
                                disabled={
                                    specialUsersState && specialUsersState.length < page * pageLimit
                                }
                                fullWidth
                                onClick={() => loadMoreUsers()}
                            >
                                {t("load_more")} &nbsp; {loadMore && <LoaderSpinner />}
                            </Button>
                        )}
                    </GridItem>
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default withTranslation("", { withRef: true })(SpecialUsers);
