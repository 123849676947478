import * as actionTypes from "../actionTypes";
import axios from "../middlewares/axios";

export const getSpecialCompany = (type = "") => async (dispatch) => {
    dispatch({ type: actionTypes.GET_SPECIAL_COMPANY_START });
    try {
        const res = await axios.get("/api/special_company");
        dispatch({
            type:
                type === "byGroup"
                    ? actionTypes.GET_SPECIAL_COMPANY_BYGROUP_SUCCESS
                    : actionTypes.GET_SPECIAL_COMPANY_SUCCESS,
            data: res.data,
        });
    } catch (error) {
        dispatch({ type: actionTypes.GET_SPECIAL_COMPANY_FAIL });
    }
};

export const getSpecialCompanyByUserId = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_SPECIAL_COMPANY_START });
    try {
        const res = await axios.get("/api/special_company/by_user/");
        dispatch({ type: actionTypes.GET_SPECIAL_COMPANY_SUCCESS, data: res.data });
    } catch (error) {
        dispatch({ type: actionTypes.GET_SPECIAL_COMPANY_FAIL });
    }
};

export const getSpecialCompanyById = (id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_SPECIAL_COMPANY_BY_ID_START });
    try {
        const res = await axios.get("/api/special_company/" + id);
        dispatch({ type: actionTypes.GET_SPECIAL_COMPANY_BY_ID_SUCCESS, data: res.data });
    } catch (error) {
        dispatch({ type: actionTypes.GET_SPECIAL_COMPANY_BY_ID_FAIL });
    }
};

export const createUpdateSpecialCompany = (update, data, history) => async (dispatch) => {
    dispatch({
        type: update
            ? actionTypes.UPDATE_SPECIAL_COMPANY_START
            : actionTypes.CREATE_SPECIAL_COMPANY_START,
    });
    try {
        const res = await axios({
            url: `${
                update ? "/api/special_company" + `/${data.about.id}` : "/api/special_company"
            }`,
            method: update ? "PUT" : "POST",
            data: data.about,
        });
        dispatch({
            type: update
                ? actionTypes.UPDATE_SPECIAL_COMPANY_SUCCESS
                : actionTypes.CREATE_SPECIAL_COMPANY_SUCCESS,
            data: res.data,
        });
        history(-1);
    } catch (error) {
        const res = error.response.data;
        const message = res.results ? res.results[Object.keys(res.results)[0]] : "";
        const errorMessage = res.message + ". " + message;
        dispatch({
            type: update
                ? actionTypes.UPDATE_SPECIAL_COMPANY_FAIL
                : actionTypes.CREATE_SPECIAL_COMPANY_FAIL,
            data: errorMessage,
        });
    }
};

export const deleteSpecialCompany = (data) => async (dispatch) => {
    try {
        await axios.delete("/api/special_company/" + data.id);
        dispatch(getSpecialCompany());
    } catch (error) {}
};
