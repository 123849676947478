import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

// core components
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Table from "components/Table/Table.js";

import { createUseStyles } from "react-jss";
import {
    Wc,
    Group,
    PersonPinCircle,
    Loyalty,
    Beenhere,
    LibraryAddCheck,
} from "@mui/icons-material";

import i18n from "../../locales/i18n";
import { getOfferReport } from "../../store/actions/offer";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { LoaderSpinner } from "../../components/Loader";
import { FlexContainer } from "components/FlexContainer/FlexContainer";
import Button from "../../components/CustomButtons/Button.js";
import formatWithThousandSeparator from "utils/thousand_separator";

const styles = {
    ...alertStyles,
    danger: {
        backgroundColor: "#e8413d",
    },

    cardIconTitle: {
        color: "#000",
    },
    container: {
        borderTop: "1px solid #717172",
        margin: "0 0 20px 0",
    },
    card: {
        margin: 0,
    },
};
const useStyles = createUseStyles(styles);

const OfferReport = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const lang = i18n.language.toLowerCase();
    const currentLang = lang.includes("is") ? "is" : "en";
    const t = props.t;
    const value = props.data;
    const { report, loadingReport } = useSelector((state) => state.offer);
    const RADIAN = Math.PI / 180;
    const [offerActivityFilter, setOfferActivityFilter] = React.useState("month");
    const [offerActivity, setOfferActivity] = React.useState([]);

    useEffect(() => {
        dispatch(getOfferReport(value.id));
    }, [dispatch]);

    useEffect(() => {
        if (report.results) {
            let data = report?.results?.activates_and_favourites;
            data.sort(function (a, b) {
                return new Date(a.name) - new Date(b.name);
            });
            setOfferActivity(data);
        }
    }, [report]);

    useEffect(() => {
        if (offerActivityFilter === "month") {
            // Filter the last month
            const lastMonth = new Date();
            lastMonth.setMonth(lastMonth.getMonth() - 1);
            const data = report?.results?.activates_and_favourites?.filter?.((item) => {
                return new Date(item.name) > lastMonth;
            });
            setOfferActivity(data);
        } else if (offerActivityFilter === "all") {
            setOfferActivity(report?.results?.activates_and_favourites);
        }
    }, [offerActivityFilter, report]);

    const genderData = report.results
        ? report.results.genders.filter((item) => item.name !== "other")
        : [
              { name: "Male", value: 0 },
              { name: "Female", value: 0 },
          ];
    const agesData =
        report.results && report.results.ages
            ? Object.keys(report.results.ages).map(function (key) {
                  return { name: key, value: report.results.ages[key] };
              })
            : [{ name: "", value: 0 }];
    const data = report.results
        ? report.results.region_of_users
        : [
              { name: "Reykjanes", value: 0 },
              { name: "Suðurland", value: 0 },
              { name: "Höfuðborgarsvæðið", value: 0 },
          ];

    const COLORS = [
        "#0088FE",
        "#00C49F",
        "#FFBB28",
        "#FF8042",
        "#A1FF3C",
        "#3D5CFF",
        "#A2D5FF",
        "#FFFA0A",
    ];
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(1)}%`}
            </text>
        );
    };
    const renderCustomizedLabelValue = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {value}
            </text>
        );
    };
    const wrapperStyle = {
        bottom: "0",
        left: 0,
        lineHeight: "24px",
    };

    return (
        <SweetAlert
            showCancel
            style={{
                display: "block",
                width: "1000px",
                maxHeight: "100%",
                overflow: "scroll",
                backgroundColor: "#efefef",
            }}
            title={currentLang === "en" ? "Statistics" : "Tölfræði"}
            onCancel={() => props.onCancel()}
            onConfirm={() => props.onCancel()}
            showConfirm={false}
            cancelBtnText={t("close")}
            showCloseButton={true}
            closeOnClickOutside={true}
            cancelBtnCssClass={classes.button + " " + classes.danger}
        >
            <h4>{currentLang === "en" && value.title_en ? value.title_en : value.title}</h4>
            {loadingReport ? (
                <LoaderSpinner />
            ) : (
                <>
                    <FlexContainer
                        style={{ justifyContent: "space-between", gap: 16, marginBottom: 32 }}
                    >
                        <Card className={classes.card}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Tilboð opnað</h4>
                            </CardHeader>

                            <h2 style={{ fontWeight: 600, margin: 0 }}>
                                {formatWithThousandSeparator(
                                    (report?.results?.totals?.offer_opened || 0) +
                                        (report?.results?.totals?.offer_opened_externally || 0)
                                )}
                            </h2>
                            <p>
                                Opnað í appi:{" "}
                                <b>
                                    {formatWithThousandSeparator(
                                        report?.results?.totals?.offer_opened || 0
                                    )}
                                </b>
                            </p>
                            <p>
                                Opnað með hlekk:{" "}
                                <b>
                                    {formatWithThousandSeparator(
                                        report?.results?.totals?.offer_opened_externally || 0
                                    )}
                                </b>
                            </p>
                        </Card>
                        <Card className={classes.card}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Tilboð virkjað</h4>
                            </CardHeader>
                            <h2 style={{ fontWeight: 600, margin: 0 }}>
                                {formatWithThousandSeparator(report?.results?.activates || 0)}
                            </h2>

                            <p>
                                Hlekkur fyrir afsláttarkóða opnaður:{" "}
                                <b>{report?.results?.totals?.offer_link || 0}</b>
                            </p>
                        </Card>{" "}
                        <Card className={classes.card}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Uppáhalds</h4>
                            </CardHeader>
                            <h2 style={{ fontWeight: 600, margin: 0 }}>
                                {formatWithThousandSeparator(report?.results?.favourites || 0)}
                            </h2>

                            <p>
                                Sett í uppáhalds:{" "}
                                <b>
                                    {formatWithThousandSeparator(
                                        report?.results?.totals?.offer_hearted || 0
                                    )}
                                </b>
                            </p>
                            <p>
                                Tekið úr uppáhalds:{" "}
                                <b>
                                    {formatWithThousandSeparator(
                                        report?.results?.totals?.offer_unhearted || 0
                                    )}
                                </b>
                            </p>
                        </Card>
                        <Card className={classes.card}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Tilboði deilt</h4>
                            </CardHeader>
                            <h2 style={{ fontWeight: 600, margin: 0 }}>
                                {formatWithThousandSeparator(
                                    report?.results?.totals?.offer_shared || 0
                                )}
                            </h2>
                        </Card>
                    </FlexContainer>

                    <FlexContainer justifyContent="space-around" column style={{ gap: 32 }}>
                        <Card className={classes.card}>
                            <CardHeader color="info" icon style={{ textAlign: "left" }}>
                                <CardIcon color="info" style={{ marginTop: -16 }}>
                                    {" "}
                                    <LibraryAddCheck />{" "}
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    {t("dash.offer_activity")}
                                </h4>
                                <Button
                                    className={classes.actionButton}
                                    color={offerActivityFilter === "all" ? "info" : "default"}
                                    size="sm"
                                    onClick={() => setOfferActivityFilter("all")}
                                >
                                    Frá upphafi
                                </Button>
                                <Button
                                    className={classes.actionButton}
                                    color={offerActivityFilter === "month" ? "info" : "default"}
                                    size="sm"
                                    onClick={() => setOfferActivityFilter("month")}
                                >
                                    Síðasti mánuður
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart
                                        height={300}
                                        data={offerActivity}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />

                                        <Bar
                                            dataKey="opens"
                                            name={t("dash.opens")}
                                            fill="#0088FE"
                                            stackId="a" // Add stackId
                                        />
                                        <Bar
                                            dataKey="activates"
                                            name={t("dash.activates")}
                                            fill="#00C49F"
                                            stackId="a" // Add stackId
                                        />
                                        <Bar
                                            dataKey="favourites"
                                            name={t("dash.favourites")}
                                            fill="#cf0000"
                                            stackId="a" // Add stackId
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardBody>
                        </Card>
                        <FlexContainer justifyContent="space-around" style={{ gap: 16 }}>
                            <Card className={classes.card}>
                                <CardHeader color="info" icon>
                                    <CardIcon color="info" style={{ marginTop: -16 }}>
                                        {" "}
                                        <Wc />{" "}
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        {t("dash.user_gender")}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <ResponsiveContainer width="100%" height={160}>
                                        <PieChart height={160}>
                                            <Pie
                                                data={genderData}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                outerRadius={60}
                                                fill="#8884d8"
                                                dataKey="value"
                                            >
                                                {genderData.map((entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={
                                                            (entry.name === "male" && "#0088FE") ||
                                                            (entry.name === "female" &&
                                                                "#f423c6") ||
                                                            (entry.name === "other" && "#FFBB28")
                                                        }
                                                    />
                                                ))}
                                            </Pie>
                                            <Legend
                                                iconSize={10}
                                                layout="horizontal"
                                                verticalAlign="bottom"
                                                wrapperStyle={wrapperStyle}
                                            />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </CardBody>
                            </Card>

                            <Card className={classes.card}>
                                <CardHeader color="info" icon>
                                    <CardIcon color="info" style={{ marginTop: -16 }}>
                                        {" "}
                                        <Group />{" "}
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>{t("dash.user_ages")}</h4>
                                </CardHeader>
                                <CardBody>
                                    <ResponsiveContainer width="100%" height={160}>
                                        <BarChart
                                            height={160}
                                            data={agesData}
                                            margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="value" name={t("users")} fill="#0088FE" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </CardBody>
                            </Card>
                            <Card className={classes.card}>
                                <CardHeader color="info" icon>
                                    <CardIcon color="info" style={{ marginTop: -16 }}>
                                        {" "}
                                        <PersonPinCircle />{" "}
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        {t("dash.user_region")}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <ResponsiveContainer width="100%" height={160}>
                                        <PieChart height={160}>
                                            <Pie
                                                data={data}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                label={renderCustomizedLabelValue}
                                                outerRadius={50}
                                                fill="#8884d8"
                                                dataKey="value"
                                            >
                                                {data.map((entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={COLORS[index % COLORS.length]}
                                                    />
                                                ))}
                                            </Pie>
                                            <Legend
                                                iconSize={10}
                                                layout="horizontal"
                                                verticalAlign="bottom"
                                                wrapperStyle={wrapperStyle}
                                            />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </CardBody>
                            </Card>
                        </FlexContainer>
                    </FlexContainer>
                </>
            )}
        </SweetAlert>
    );
};

export default withTranslation("", { withRef: true })(OfferReport);
