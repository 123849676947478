import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardReport } from "../../store/actions/dashboard";
import { withTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    AreaChart,
    Area,
} from "recharts";
import {
    Group,
    Business,
    ListAlt,
    FiberManualRecord,
    Language,
    LibraryAddCheck,
    SupervisedUserCircle,
    PlaylistAddCheck,
} from "@mui/icons-material";

import GoogleMapNew from "../../components/GoogleMap/googleMapNew";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Table from "../../components/Table/Table.js";
import Button from "../../components/CustomButtons/Button.js";
import styles from "../../assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { LoaderSpinner } from "../../components/Loader";
import GroupListItem from "components/GroupList/GroupListItem";
import formatWithThousandSeparator from "utils/thousand_separator";

const useStyles = createUseStyles(styles);

const Dashboard = ({ t }) => {
    const classes = useStyles();
    const { dashboard, loading } = useSelector((state) => state.dashboard);
    const [acFaStatus, setAcFaStatus] = React.useState("week");
    const [newUserStatus, setNewUserStatus] = React.useState("week");
    const dispatch = useDispatch();
    const RADIAN = Math.PI / 180;

    useEffect(() => {
        dispatch(getDashboardReport());
    }, [dispatch]);

    if (loading) return <LoaderSpinner />;
    const genderData = dashboard.results
        ? dashboard.results.genders?.filter?.((item) => item.name !== "other")
        : [
              { name: "Male", value: 0 },
              { name: "Female", value: 0 },
          ];
    const offerActivateAndFav = dashboard.results
        ? acFaStatus === "week"
            ? dashboard.results.activates_and_favourites_by_week
            : dashboard.results.activates_and_favourites_by_year
        : [{ name: "", activates: 0, favourites: 0 }];
    const newUsers = dashboard.results
        ? newUserStatus === "week"
            ? dashboard.results.new_users_by_week
            : dashboard.results.new_users_by_year
        : [{ date: "", total: 0 }];
    const agesData =
        dashboard.results && dashboard.results.ages
            ? Object.keys(dashboard.results.ages)?.map?.(function (key) {
                  return { name: key, value: dashboard.results.ages[key] };
              })
            : [{ name: "", value: 0 }];

    const COLORS = [
        "#BB001D",
        "#008353",
        "#D09600",
        "#FF8042",
        "#023e8a",
        "#16B4F3",
        "#DB18EC",
        "#F2CB04",
    ];
    const data = dashboard.results
        ? dashboard.results.region_of_users
        : [
              { name: "Reykjanes", value: 0 },
              { name: "Suðurland", value: 0 },
              { name: "Höfuðborgarsvæðið", value: 0 },
          ];
    const style = {
        bottom: "0",
        left: 0,
        lineHeight: "24px",
    };

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(1)}%`}
            </text>
        );
    };
    const offerByRegiontableData = !dashboard.results
        ? [[]]
        : dashboard.results.regions?.map?.((value, index) => {
              return [
                  value.value ? <span className={classes.regName}>{value.value}</span> : "",
                  value.count ? value.count : 0,
              ];
          });

    const xAxisTickFormatter = (date) => {
        return t(date);
    };

    return (
        <div>
            <div
                style={{
                    display: "grid",
                    gap: 16,
                    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                    marginBottom: 16,
                }}
            >
                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <Group />
                        </CardIcon>
                        <p className={classes.cardCategory2}> {t("dash.users")} </p>
                        <h3 className={classes.cardTitle}>
                            {formatWithThousandSeparator(dashboard?.results?.users)}
                        </h3>
                    </CardHeader>
                </Card>

                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <Business />
                        </CardIcon>
                        <p className={classes.cardCategory2}> {t("dash.companies")} </p>
                        <h3 className={classes.cardTitle}>
                            {formatWithThousandSeparator(dashboard?.results?.companies)}
                        </h3>
                    </CardHeader>
                </Card>

                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <ListAlt />
                        </CardIcon>
                        <p className={classes.cardCategory2}> {t("dash.offers")} </p>
                        <h3 className={classes.cardTitle}>
                            {formatWithThousandSeparator(dashboard?.results?.offers)}
                        </h3>
                    </CardHeader>
                </Card>
            </div>

            <div
                style={{
                    display: "grid",
                    gap: 16,
                    gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr))",
                }}
            >
                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <SupervisedUserCircle />
                        </CardIcon>
                        <p className={classes.cardCategory2}> {t("new_users")}</p>
                        <Button
                            className={classes.actionButton}
                            color={newUserStatus === "week" ? "info" : "default"}
                            size="sm"
                            onClick={() => setNewUserStatus("week")}
                        >
                            {t("month")}
                        </Button>
                        <Button
                            className={classes.actionButton}
                            color={newUserStatus === "year" ? "info" : "default"}
                            size="sm"
                            onClick={() => setNewUserStatus("year")}
                        >
                            {t("year")}
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <ResponsiveContainer width="100%" height={300}>
                            <AreaChart
                                width="100%"
                                height="100%"
                                data={newUsers}
                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                {newUserStatus === "week" ? (
                                    <XAxis dataKey="date" />
                                ) : (
                                    <XAxis dataKey="month" tickFormatter={xAxisTickFormatter} />
                                )}
                                <YAxis />
                                {newUserStatus === "week" ? (
                                    <Tooltip />
                                ) : (
                                    <Tooltip labelFormatter={xAxisTickFormatter} />
                                )}
                                <Area
                                    type="monotone"
                                    dataKey="total"
                                    stroke="#007AAA"
                                    fill="#A2E1FA"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </CardBody>
                </Card>
                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <LibraryAddCheck />
                        </CardIcon>
                        <h4 className={classes.cardCategory2}>{t("dash.offers_activity")}</h4>
                        <Button
                            className={classes.actionButton}
                            color={acFaStatus === "week" ? "info" : "default"}
                            size="sm"
                            onClick={() => setAcFaStatus("week")}
                        >
                            {t("month")}
                        </Button>
                        <Button
                            className={classes.actionButton}
                            color={acFaStatus === "year" ? "info" : "default"}
                            size="sm"
                            onClick={() => setAcFaStatus("year")}
                        >
                            {t("year")}
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <ResponsiveContainer width="100%" height={270}>
                            <BarChart
                                width="100%"
                                height="100%"
                                data={offerActivateAndFav}
                                margin={{ top: 5, right: 10, left: 5, bottom: 0 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                {acFaStatus === "week" ? (
                                    <XAxis dataKey="date" />
                                ) : (
                                    <XAxis dataKey="month" tickFormatter={xAxisTickFormatter} />
                                )}
                                <YAxis />
                                {acFaStatus === "week" ? (
                                    <Tooltip />
                                ) : (
                                    <Tooltip labelFormatter={xAxisTickFormatter} />
                                )}
                                <Bar
                                    dataKey="opens"
                                    name={t("dash.opens")}
                                    fill="#16B4F3"
                                    stackId="a" // Add stackId
                                />
                                <Bar
                                    dataKey="activates"
                                    name={t("dash.activates")}
                                    fill="#008B6B"
                                    stackId="a" // Add stackId
                                />
                                <Bar
                                    dataKey="favourites"
                                    name={t("dash.favourites")}
                                    fill="#DE2C48"
                                    stackId="a" // Add stackId
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardBody>
                    <CardFooter stats className={classes.cardFooter} style={{ marginTop: "5px" }}>
                        <FiberManualRecord style={{ color: "#16B4F3" }} /> {t("dash.opens")}
                        {` `} (
                        {dashboard.results
                            ? acFaStatus === "week"
                                ? formatWithThousandSeparator(
                                      dashboard.results.offer_opened_by_week_count
                                  )
                                : formatWithThousandSeparator(
                                      dashboard.results.offer_opened_by_year_count
                                  )
                            : 0}
                        )
                        <FiberManualRecord style={{ color: "#008B6B" }} /> {t("dash.activates")}
                        {` `} (
                        {dashboard.results
                            ? acFaStatus === "week"
                                ? formatWithThousandSeparator(
                                      dashboard.results.offer_activates_by_week_count
                                  )
                                : formatWithThousandSeparator(
                                      dashboard.results.offer_activates_by_year_count
                                  )
                            : 0}
                        )
                        <FiberManualRecord style={{ color: "#DE2C48" }} /> {t("dash.favourites")}
                        {` `} (
                        {dashboard.results
                            ? acFaStatus === "week"
                                ? formatWithThousandSeparator(
                                      dashboard.results.user_favourites_by_week_count
                                  )
                                : formatWithThousandSeparator(
                                      dashboard.results.user_favourites_by_year_count
                                  )
                            : 0}
                        )
                    </CardFooter>
                </Card>
            </div>

            <Card style={{ margin: "16px 0px" }}>
                <CardHeader>
                    <CardIcon color="rose">
                        <Language />
                    </CardIcon>
                    <h4 className={classes.cardCategory}>{t("dash.number_offers")}</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer justifyContent="space-between">
                        <GridItem xs={12} sm={12} md={4}>
                            <Table tableData={offerByRegiontableData} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                            {dashboard.results && dashboard.results.offer_locations && (
                                <GoogleMapNew companies={dashboard.results.offer_locations} />
                            )}
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>

            <div
                style={{
                    display: "grid",
                    gap: 16,
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    marginBottom: 16,
                }}
            >
                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <Group />
                        </CardIcon>
                        <h4 className={classes.cardCategory}>{t("dash.user_gender")}</h4>
                    </CardHeader>
                    <CardBody>
                        <ResponsiveContainer width="100%" height={200}>
                            <PieChart width={200} height={200}>
                                <Pie
                                    data={genderData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={100}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {genderData?.map?.((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={
                                                (entry.name === "male" && "#007AAA") ||
                                                (entry.name === "female" && "#DB18EC") ||
                                                (entry.name === "other" && "#F2CB04")
                                            }
                                        />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </CardBody>
                    <CardFooter stats className={classes.cardFooter}>
                        <FiberManualRecord
                            className={classes.primary}
                            style={{ color: "#007AAA" }}
                        />
                        {t("male")}
                        {` `}
                        <FiberManualRecord
                            className={classes.warningclasses}
                            style={{ color: "#DB18EC" }}
                        />
                        {t("female")}
                        {` `}
                        {/*<FiberManualRecord className={"warning"}  />{t('other')}{` `}*/}
                    </CardFooter>
                </Card>
                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <Group />
                        </CardIcon>
                        <h4 className={classes.cardCategory}>{t("dash.user_ages")}</h4>
                    </CardHeader>
                    <CardBody>
                        <ResponsiveContainer width="100%" height={260}>
                            <BarChart
                                width={500}
                                height={200}
                                data={agesData}
                                margin={{ top: 5, right: 10, left: 5, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" name={t("users")} fill="#16B4F3" />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardBody>
                </Card>
                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <Group />
                        </CardIcon>
                        <h4 className={classes.cardCategory}>{t("dash.user_region")}</h4>
                    </CardHeader>
                    <CardBody>
                        <ResponsiveContainer width="100%" height={260}>
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={data}
                                    cx="50%"
                                    cy="60%"
                                    labelLine={true}
                                    // label={renderCustomizedLabel}
                                    outerRadius={70}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label
                                >
                                    {data?.map?.((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend
                                    iconSize={10}
                                    layout="horizontal"
                                    verticalAlign="bottom"
                                    wrapperStyle={style}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </CardBody>
                </Card>
            </div>
            <div
                style={{
                    display: "grid",
                    gap: 16,
                    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                }}
            >
                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <SupervisedUserCircle />
                        </CardIcon>
                        <h4 className={classes.cardCategory}> {t("dash.special_user")} </h4>
                    </CardHeader>
                    <CardBody>
                        <div
                            style={{
                                display: "grid",
                                gap: 8,
                                gridTemplateColumns: "repeat(auto-fit, minmax(165px, 1fr))",
                            }}
                        >
                            {dashboard?.results?.special_users?.map?.((value, index) => {
                                value.value = formatWithThousandSeparator(value.value);

                                return (
                                    <GroupListItem
                                        key={"specialUsersKey" + index}
                                        name={value.name}
                                        logo={value.logo}
                                        description={value.value}
                                    />
                                );
                            })}
                        </div>
                    </CardBody>
                </Card>

                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <PlaylistAddCheck />
                        </CardIcon>
                        <h4 className={classes.cardCategory}> {t("dash.special_offers")} </h4>
                    </CardHeader>
                    <CardBody>
                        <div
                            style={{
                                display: "grid",
                                gap: 8,
                                gridTemplateColumns: "repeat(auto-fit, minmax(165px, 1fr))",
                            }}
                        >
                            {dashboard?.results?.special_offers?.map?.((value, index) => {
                                // value.torgid_offers_count should be thousand separated
                                value.torgid_offers_count = formatWithThousandSeparator(
                                    value.torgid_offers_count
                                );
                                return (
                                    <GroupListItem
                                        key={"specialOffersKey" + index}
                                        name={value.name}
                                        logo={value.logo}
                                        description={value.torgid_offers_count}
                                    />
                                );
                            })}
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default withTranslation("", { withRef: true })(Dashboard);
